// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-atomatic-swap-index-js": () => import("./../../../src/pages/projects/atomatic-swap/index.js" /* webpackChunkName: "component---src-pages-projects-atomatic-swap-index-js" */),
  "component---src-pages-projects-caves-index-js": () => import("./../../../src/pages/projects/caves/index.js" /* webpackChunkName: "component---src-pages-projects-caves-index-js" */),
  "component---src-pages-projects-caves-koo-san-index-js": () => import("./../../../src/pages/projects/caves/koo-san/index.js" /* webpackChunkName: "component---src-pages-projects-caves-koo-san-index-js" */),
  "component---src-pages-projects-caves-mine-index-js": () => import("./../../../src/pages/projects/caves/mine/index.js" /* webpackChunkName: "component---src-pages-projects-caves-mine-index-js" */),
  "component---src-templates-atomatic-article-tpl-index-js": () => import("./../../../src/templates/Atomatic/ArticleTpl/index.js" /* webpackChunkName: "component---src-templates-atomatic-article-tpl-index-js" */)
}

